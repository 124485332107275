import getRequiredServiceTerms, {
  RequiredServiceTerms,
} from 'helpers/get-required-service-terms';
import getToken from 'helpers/get-token';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

const FooterContainer = styled.div`
  width: 100vw;
  padding: 10px 0;
  padding-top: 60px;

  @media (min-width: 575px) {
    bottom: 0;
    padding: 10px 0 30px;
  }

  @media (max-height: 500px) {
    bottom: initial;
    padding: 10px 0 30px;
  }
`;

const Col = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
`;

const FooterInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 575px) {
    flex-direction: row;
  }
`;

const Text = styled.p`
  ${({ theme }) => {
    const styles = theme.typography.footer;
    return `
      font-family: ${styles.fontFamily};
      color: ${styles.color};
    `;
  }}
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin-right: 0;
  margin-bottom: 0;

  @media (min-width: 575px) {
    margin-right: 44px;
  }
`;

const List = styled.ul`
  display: flex;
  margin: 0 -22px;
`;

const Item = styled.li`
  padding: 0 22px;
  list-style: none;
`;

const Link = styled.a`
  ${({ theme }) => {
    const styles = theme.typography.footer;
    return `
      font-family: ${styles.fontFamily};
      color: ${styles.color};
    `;
  }}
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-decoration: none;
`;

const AuthFooter = (): JSX.Element => {
  const [requiredServiceTerms, setRequiredServiceTerms] =
    useState<RequiredServiceTerms | null>(null);
  const token = getToken(window.location.search);

  useEffect(() => {
    let isAuthFooterMounted = true;
    const fetchData = async () => {
      const data = await getRequiredServiceTerms(token);
      if (isAuthFooterMounted) {
        setRequiredServiceTerms(data);
      }
    };
    fetchData();
    return () => {
      isAuthFooterMounted = false;
    };
  }, [token]);

  return (
    <FooterContainer>
      <Col>
        <FooterInner>
          <Text>© Nayya Health, Inc. All rights reserved.</Text>
          {requiredServiceTerms && (
            <List>
              <Item>
                <Link
                  href={requiredServiceTerms.privacy_policy_content}
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </Item>
              <Item>
                <Link
                  href={requiredServiceTerms.end_user_content}
                  target="_blank"
                >
                  Terms of Service
                </Link>
              </Item>
              <Item>
                <Link href="https://support.nayya.com/hc/en-us" target="_blank">
                  Help
                </Link>
              </Item>
            </List>
          )}
        </FooterInner>
      </Col>
    </FooterContainer>
  );
};

export default AuthFooter;
